body {
	color:#ddd;
	background:#333;
}

a {
	color:#60B1E2;

	&:hover {
		color:#D00;
	}

	&.highlight_green {
		color: #43c20c;

		&:hover {
			color: #A00;
		}
	}

	&.highlight_red {
		color: #E00000;

		&:hover {
			color: #A00;
		}
	}

	&.highlight_yellow {
		color: #e0c400;

		&:hover {
			color: #A00;
		}
	}
}

fieldset {
	background:#444;
}

.textcolorerror {
	color:#F00;
}

.textcolorgood {
	color:#00BD00;
}

legend {
	color:#60B1E2;
}

.button {
	background: #555;
}

.title {
	background-image:linear-gradient(90deg, #343a40, transparent 100%);
	color:#BBB;
	/* text-shadow: 0 -1px #FFF, 1px 0 #FFF, 0 1px #FFF, -1px 0 #FFF; */
	text-shadow:0.1px 0.1px 3px #000;
}

.errormessage {
	color:#FF4C4C;
}

.botmanagerwarning {
	color:#FF4400;
}

hr.borderline {
	border-bottom-color:#AAAAAA;
}

.borderline {
	border-bottom-color:#AAAAAA;
}

.languageimagechooser:hover {
	border-color:#3300FF;
}

.button_active {
	background:#444 !important;
}

.button:hover {
	background:#444;
}

table.bbcode, select.bbcode {
	background:#bbb;
}

select.bbcode:focus {
	background:#aaa;
}

.bbcode_message_keyword {
	color:#00C300;
}

.bbcode_message_highlight_warning {
	color:#FF4400;
}

.bbcode_message_highlight_notice {
	color:#00C300;
}

.list-group-item {
	color: #1b1e21;
	background-color: #343a40;
}

.form-control {
	background-color: #3b4248;
	color: #b3bbbf;
	border: 1px solid #454649;

	&:disabled, &[readonly] {
		background-color: darken(#3b4248, 10%);
		color: darken(#b3bbbf, 10%);
		border: 1px solid darken(#454649, 10%);
	}
}

.text-themed {
	color: #fff !important;
}

.modal-content {
	background-color: #343a40;
	color: #f8f9fa;

	.modal-header {
		border-bottom: 1px solid rgba(0,0,0,.125);
	}

	.modal-footer {
		border-top: 1px solid rgba(0,0,0,.125);
	}
}

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
	border-bottom-color: #343a40;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -.5rem;
	content: "";
	border-bottom: 1px solid #343a40;
}

.popover {
	background-color: #343a40;
	color: #f8f9fa;

	.popover-header {
		background-color: #343a40;
		border-color: rgba(0,0,0,.125);
	}

	.popover-body {
		color: #f8f9fa;
	}
}